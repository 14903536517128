import React from "react"
import Img from "gatsby-image"


export default({image, author}) => {
            return (
                <div class="block">
                    <div class="drawing">
                        <Img fixed={image} />
                    </div>

                    <div class="authortext">
                        {author}
                    </div>
                </div>                
            )
}

