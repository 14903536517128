import React from "react"
import { useStaticQuery, graphql } from "gatsby"


import Layout from "../components/layout"
import Drawing from "../components/drawing"
import DrawingLambda from "../components/drawinglambda"



const Drawings = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicDrawing {
        nodes {
          id
          data {
            datepicker
            title {
              text
            }
            image {
              alt
              copyright
              url
              dimensions {
                height
                width
              }
              localFile {
                relativePath
                childImageSharp {
                  id
                  fixed(width: 256, height: 256) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
        <div>
        {
            data.allPrismicDrawing.nodes.map(drawData => {
                return (<div><Drawing image={drawData.data.image.localFile.childImageSharp.fixed} author={drawData.data.title.text} /></div>)
            })
        }
        </div>
        <div>
        {
            data.allPrismicDrawing.nodes.map(drawData => {
                return (<div><DrawingLambda image={drawData.data.image.localFile.childImageSharp.fixed} author={drawData.data.title.text} /></div>)
            })
        }
        </div>
    </Layout>
    );
}



export default Drawings
